module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://myca.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr"],"defaultLanguage":"en","siteUrl":"https://myca.com","i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/careers/:uid","getLanguageFromPath":true,"excludeLanguages":["fr"]},{"matchPath":"/:lang?/carrieres/:uid","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/careers","getLanguageFromPath":true,"excludeLanguages":["fr"]},{"matchPath":"/:lang?/carrieres","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/carrieres/postuler","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/careers/apply/","getLanguageFromPath":true,"excludeLanguages":["fr"]},{"matchPath":"/services","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/services","getLanguageFromPath":true,"excludeLanguages":["fr"]},{"matchPath":"/privacy/","getLanguageFromPath":false,"excludeLanguages":["fr"]},{"matchPath":"/terms/","getLanguageFromPath":false,"excludeLanguages":["fr"]},{"matchPath":"/appointment-confirmation/","getLanguageFromPath":false,"excludeLanguages":["fr"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"514a4658b64d80a3be849b9b0ece49fd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://localhost/cms-myca-2021/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHNRXPP","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
